import {ref, reactive, toRefs, computed, defineComponent,onBeforeMount, onMounted, getCurrentInstance, watch, provide} from 'vue';
import XshdListUtil,{IXshdListDataObj} from './xshdListUtil';
export default defineComponent ({
    name: 'XshdList',
    setup(){
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let dataObj:IXshdListDataObj=reactive<IXshdListDataObj>({
            pageListRef:null,
            utilInst:{} as any,
            refMap:new Map(),
            pageList: {
                isShowFixCol:false,
                queryParam: {},
                modelMethod: utils.ToolsProviderApi.buildUrl('/xshd/pageData')
            }
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new XshdListUtil(proxy,dataObj);
        })
        onMounted(()=>{
        })
        //---------------------------computed------------
        //下拉选择
        const comboSelect=computed(()=>{
            return (params:any) => {
                if(params && 'type'==params.comboId){
                    return [{value:1,label:'转介绍到店'},{value:2,label:'转介绍下单'},{value:3,label:'参加物流工作'},{value:4,label:'加好友'},{value:5,label:'参加社群活动'}]
                }
            }
        })
        return{
            ...toRefs(dataObj),comboSelect
        }
    }
});