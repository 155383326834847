import BaseBean from "@/utils/BaseBean";

export interface IXshdListDataObj {
    utilInst:XshdListUtil
    refMap:Map<string,any>
    pageListRef:any
    pageList: any
}

export default class XshdListUtil extends BaseBean{
    public dataObj:IXshdListDataObj

    constructor(proxy:any,dataObj:IXshdListDataObj) {
        super(proxy);
        this.dataObj=dataObj;
    }
}